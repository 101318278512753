* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
}

textarea {
  font-family: inherit;
  resize: none;
}

body {
  margin: 0;
  font-size: 1rem;
  background-color: #14161a;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

img {
  max-width: 100%;
  display: block;
}

.container {
  position: relative;
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "nav";
  width: 100%;
  height: 100dvh;
  margin: 0 auto;
  grid-template-rows: 50px auto 50px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #0088ff;
  background-color: #14161a;
  grid-area: header;
  border-bottom: 1px solid #0088ff;
  z-index: 7;
}

.header-svg-wrapper,
.nav-el-svg-wrapper {
  position: relative;
  display: flex;
  font-size: 1.35rem;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  transition: background-color 0.2s;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-svg-wrapper:hover,
.nav-el-svg-wrapper:hover {
  background-color: #003059;
}

.header-svg-wrapper.active {
  background-color: #003059;
}

.alert-badge {
  position: absolute;
  top: 0rem;
  right: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  color: #fff;
  font-size: 0.7rem;
  border-radius: 100%;
  background-color: #d5372f;
}

.header span {
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
}

.content {
  display: flex;
  width: 100%;
  background-color: #212121;
  grid-area: content;
  overflow: hidden;
}

.side-pannel-container {
  display: none;
  grid-template-rows: auto 50px;
  gap: 1rem;
  width: 100%;
  height: 100%;
  max-width: 447px;
  margin-left: auto;
  border-left: 1px solid #404040;
}

.side-pannel-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: #fff;
}

.side-pannel-header {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
}

.side-pannel-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 1rem;
  scrollbar-width: none;
}

.side-pannel-body-el {
  height: 100px;
  width: 100%;
  display: flex;
  border: 1px solid #007bff;
}

.side-pannel-body-el .photo {
  background-size: cover;
  background-position: center;
  min-width: 130px;
  height: 100%;
}

.side-pannel-body-el .info {
  padding: 0.5rem;
  overflow: hidden;
  width: 100%;
}

.side-pannel-body-el .no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.side-pannel-body-el .name {
  font-size: 1.2rem;
}
.side-pannel-body-el .location,
.side-pannel-body-el .timestamp {
  font-size: 0.9rem;
  opacity: 0.7;
  font-weight: 300;
}

.side-pannel-empty {
  text-align: center;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0.5;
  font-weight: 300;
  border-top: 1px solid #404040;
  width: 100%;
}

.nav {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  background-color: #14161a;
  color: #0088ff;
  grid-area: nav;
  border-top: 1px solid #0088ff;
  z-index: 3;
  scrollbar-width: none;
}

.nav-el {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s;
}

.nav-el.active {
  background-color: #003059;
}

.nav-el-svg-wrapper.photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-el-svg-wrapper.create {
  background-color: #0088ff;
  color: #14161a;
  opacity: 0.8;
  transition: all 0.2s;
}

.nav-el-svg-wrapper.create:hover {
  opacity: 1;
}

.nav-el-svg-wrapper .nav-photo {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid #0088ff;
  background-size: cover;
  background-position: center;
}

.nav-el-expanded {
  display: none;
}

.nav span {
  display: none;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  background-color: #14161a;
  height: 100%;
  width: 70%;
  padding: 64px 0.5rem 1rem 0.5rem;
  z-index: 6;
}

.sidebar-el {
  color: #0088ff;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  text-wrap: nowrap;
  overflow: hidden;
}

.sidebar-el:hover {
  background-color: #000e1a;
}

.sidebar-el.active {
  background-color: #003059;
}

.sidebar-el.create {
  color: #000e1a;
  opacity: 0.8;
  background-color: #0088ff;
}

.sidebar-el.create.active {
  opacity: 1;
}

.sidebar-el.create:hover {
  opacity: 1;
}

.sidebar-el:last-child {
  margin-top: auto;
  border-bottom: 1px solid #0088ff;
  border-radius: 1rem 1rem 0 0;
  margin-bottom: 2rem;
}

.sidebar-el-svg-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  margin-right: 1rem;
}

@media (min-width: 500px) {
  .container {
    grid-template-areas:
      "header content"
      "nav content";
    grid-template-rows: 50px auto;
    grid-template-columns: 82px auto;
    border-left: 1px solid #0088ff;
    border-right: 1px solid #0088ff;
    max-width: 685px;
  }

  .header {
    border-bottom: none;
    border-right: 1px solid #0088ff;
    font-size: 0.9rem;
  }

  .header-svg-wrapper {
    display: none;
    font-size: 1rem;
  }

  .nav {
    flex-direction: column;
    overflow-y: auto;
    border-top: none;
    border-right: 1px solid #0088ff;
  }

  .nav-el:first-child {
    margin-top: 0;
  }

  .nav-el {
    height: auto;
    margin-top: 1rem;
  }

  .nav-el:last-child {
    margin-top: auto;
    margin-bottom: 1rem;
  }

  .nav-el-svg-wrapper {
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
  }

  .nav-el-expanded {
    display: flex;
  }

  .sidebar-nav,
  .sidebar-overlay {
    display: none;
  }

  .content {
    max-width: 600px;
  }

  .alert-badge {
    top: 0.5rem;
    right: 0.5rem;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 1050px;
  }

  .content {
    max-width: none;
    width: 100%;
  }

  .side-pannel-container {
    display: grid;
  }
}

@media (min-width: 1200px) {
  .container {
    grid-template-columns: 300px auto;
    border-right: 1px solid #0088ff;
    border-left: 1px solid #0088ff;
    max-width: 1350px;
  }

  .header span {
    text-align: left;
    margin-left: 1rem;
    font-size: 1.1rem;
  }

  .nav {
    flex-direction: column;
    overflow-y: auto;
    padding-left: 1rem;
  }

  .nav-el-svg-wrapper:hover {
    background-color: initial;
  }

  .nav-el {
    flex-direction: row;
    justify-content: unset;
    border-radius: 1rem;
    padding-right: 1rem;
    width: fit-content;
    font-size: 1.25rem;
  }

  .nav-el:not(.create):hover {
    background-color: #003059;
  }

  .nav span {
    display: block;
  }

  .nav-el-expanded.create {
    background-color: #0088ff;
    color: #000e1a;
    opacity: 0.8;
  }

  .nav-el-expanded.create:hover {
    opacity: 1;
  }
}

.sidebar-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #000;
  z-index: 5;
  top: 0;
}

/* Disables the blue border on the map */
.gm-style iframe + div {
  border: none !important;
}

/* SEARCH BAR */

.search-bar {
  width: 100%;
  background-color: #14161a;
  color: #0088ff;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  border-bottom: 2px solid black;
  justify-content: space-between;
  z-index: 0;
}

.search-bar .icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

.search-bar a {
  color: #0088ff;
}

.search-bar-text {
  cursor: pointer;
}

/* SPOT DETAIL */

.spot-detail {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 62px;
  height: 100%;
  width: 100%;
  background-color: #212121;
  color: #fff;
  z-index: 10;
}

@media (min-width: 600px) {
  .spot-detail {
    position: unset;
  }
}

.spot-detail-content {
  overflow-y: auto;
  scrollbar-width: none;
}

.spot-detail-photo {
  min-height: max(35%, 270px);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.spot-detail-photo .back-btn,
.spot-detail-photo .options-btn {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #14161a;
  color: #0088ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  opacity: 0.85;
  transition: opacity 0.2s;
}

.spot-detail-photo .back-btn:hover {
  opacity: 1;
}

.spot-detail-header {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spot-detail-header .name {
  font-size: 1.35rem;
  line-height: 1.1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spot-detail-header .engagement {
  font-size: 0.8rem;
  opacity: 0.8;
}

.spot-detail-header .author {
  display: flex;
  gap: 0.25rem;
  max-width: fit-content;
  cursor: pointer;
}

.spot-detail-header .author:hover {
  text-decoration: underline;
}

.spot-detail-header .image {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
}

.spot-detail-info .info-el {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  border-top: 1px solid #0088ff;
  padding: 0.75rem 1rem;
}

.spot-detail-info .info-el:last-child {
  border-bottom: 1px solid #0088ff;
}

.spot-detail-info .info-el svg {
  min-width: 20px;
  height: 20px;
  color: #0088ff;
  margin-right: 1rem;
}

.spot-detail-info .info-el .note-edit {
  font-weight: 500;
  color: #0088ff;
  cursor: pointer;
}

.note-content {
  word-break: break-all;
}
.spot-detail-info .info-el .note-edit:hover {
  text-decoration: underline;
}

.spot-detail-insights {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  color: #fff;
}

.insights-header {
  font-size: 1.25rem;
}

.insight-add {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
}

.insight-add .photo {
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
}

.insight-add textarea {
  width: 100%;
  background-color: #212121;
  border: none;
  outline: none;
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #4b4b4b;
  font-family: inherit;
  color: inherit;
  field-sizing: content;
  max-height: 80px;
  transition: all 0.2s;
}

.insight-add textarea:focus {
  border-bottom: 1px solid #fff;
}

.insight-add button {
  background-color: #0088ff;
  height: 34px;
  width: 64px;
  border-radius: 1rem;
  transition: all 0.2s;
}

.insight-add button:hover {
  background-color: #0056b3;
}

.spot-insights {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spot-detail-options {
  margin-top: auto;
  width: 100%;
  display: flex;
  gap: 1rem;
  background-color: #14161a;
  justify-content: space-around;
  border-top: 1px solid #0088ff;
  padding: 0.5rem 1rem;
}

.spot-detail-options .option-el {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  border: 1px solid #0088ff;
  color: #0088ff;
  text-wrap: nowrap;
  transition: all 0.2s;
  opacity: 0.8;
}

.spot-detail-options .option-el:hover {
  opacity: 1;
}

.spot-detail-options .option-el svg {
  font-size: 1.25rem;
}

.spot-detail-options .active {
  background-color: #0088ff;
  color: #14161a;
}

/* SPOTLISTS */

.spotlists {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  min-width: 100%;
}

.spotlists-header {
  font-size: 1.5rem;
  padding: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
  color: #fff;
}

.spotlists-body {
  height: 100%;
}

.spotlists-wrapper {
  padding: 1rem;
}

.spotlists-wrapper {
  display: grid;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 500px) {
  .spotlists-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1000px) {
  .spotlists-wrapper:not(.profile-spotlists-container .spotlists-wrapper) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.spotlists-el {
  display: grid;
  grid-template-columns: 50% auto 30px;
  grid-template-rows: max-content min-content;
  gap: 0.5rem;
  color: #fff;
  width: 100%;
}

.spotlists-thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
}

.spotlists-like-count {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.spotlists-like-count.active svg {
  color: #fa507d;
}

.spotlists-spot-count {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.spotlists-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  line-height: 1.3;
  overflow: hidden;
}

.spotlists-title {
  font-size: 0.9rem;
  font-weight: 500;
}

.spotlists-info p {
  margin: 0;
  font-size: 0.75rem;
  opacity: 0.8;
  font-weight: 300;
}

.spotlists-title,
.spotlists-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spotlists-menu button {
  position: relative;
  background: none;
  border: none;
  color: #aaa;
  font-size: 1rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-bottom: auto;
  border-radius: 100%;
}

.spotlists-menu button:hover,
.spotlists-options-popup .options-item:hover {
  background-color: #4b4b4b;
  cursor: pointer;
}

.spotlists-options-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 250px;
  right: 2rem;
  margin-top: 2.7rem;
  z-index: 2;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.spotlists-options-popup .options-item {
  display: flex;
  place-items: center;
  height: 49px;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  padding-left: 1rem;
  background-color: #333;
  color: #fff;
  border: none;
  gap: 1rem;
}

@media (min-width: 500px) {
  .spotlists-el {
    grid-template-columns: 1fr auto;
  }

  .spotlists-thumbnail {
    grid-column: span 2;
  }

  .spotlists-title,
  .spotlists-description {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .spotlists-menu {
    position: relative;
  }
}

.empty-spotlists-message {
  color: #fff;
  padding: 1rem;
  text-align: center;
}

/* ADD NOTE */

.add-note-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  max-width: 385px;
  background-color: #282828;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}

.add-note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.35rem;
}

.add-note-header .close-button {
  background-color: #282828;
  color: #fff;
  padding: 0;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.add-note-textarea {
  background-color: inherit;
  color: #fff;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  width: 100%;
  height: 90px;
  padding: 0.5rem;
  font-size: 1rem;
  outline: none;
}

.add-note-textarea:focus {
  border: 1px solid #0088ff;
}

.add-note-btn {
  margin-top: 0.5rem;
  align-self: flex-end;
  font-size: 1.1rem;
  color: #0088ff;
  border-radius: 1rem;
  cursor: pointer;
  background-color: #282828;
  border: none;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
}

.add-note-btn:hover {
  background-color: #333;
}

/* ADD TO SPOTLIST */

.spotlist-add-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 2rem);
  max-width: 385px;
  max-height: 430px;
  background-color: #282828;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem 0rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}

.spotlist-add-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.15rem;
}

.spotlist-add-header .close-button {
  background-color: #282828;
  color: #fff;
  padding: 0;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.spotlist-add-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0rem;
  height: 100%;
  padding: 0 2rem;
  overflow: hidden;
  overflow-y: auto;
}

.spotlist-add-list svg {
  width: 20px;
}

.spotlist-add-list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.spotlist-add-checkbox {
  all: unset;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px; /* Rounded corners */
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
}

.spotlist-add-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.spotlist-add-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -55%) rotate(45deg);
  transform-origin: center;
}

.spotlist-add-list-item label {
  width: 100%;
  margin-left: 1rem;
}

.spotlist-btn {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0 2rem;
  height: 64px;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.spotlist-add-new-btn {
  background-color: #4b4b4b;
}

.spotlist-add-new-btn:hover {
  background-color: #626262;
}

.spotlist-add-to-btn {
  margin-top: 0.5rem;
  background-color: #0056b3;
  transition: all 0.2s;
}

.spotlist-add-to-btn:hover {
  background-color: #007bff;
}

.spotlist-shade {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 10;
}

.spotlist-add-empty {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
}

/* CREATE NEW SPOTLIST */

.spotlist-create-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  max-width: 320px;
  background-color: #282828;
  color: #fff;
  border-radius: 1rem;
  padding: 1rem 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}

.spotlist-create-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
}

.spotlist-create-header .close-button {
  background-color: #282828;
  color: #fff;
  padding: 0;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.spotlist-create-input label {
  display: block;
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.spotlist-create-visibility option {
  padding: 1rem;
}

.spotlist-create-container textarea,
.spotlist-create-container input,
.spotlist-create-container select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  border: none;
  background-color: #404040;
  color: #fff;
  outline: none;
}

.spotlist-create-container textarea {
  field-sizing: content;
  scrollbar-width: none;
  max-height: 75px;
}

.spotlist-create-btn {
  background-color: #0088ff;
  color: #fff;
  height: 48px;
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.spotlist-create-btn:hover {
  background-color: #0056b3;
}

.spotlist-create-input .input-file {
  display: none;
}

.spotlist-create-input .spot-photo {
  background-size: cover;
  background-position: center;
  height: 120px;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
}

.current-location {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #0088ff;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LOADING SPINNER */

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
}

.loader.big {
  min-width: 100%;
}

.loader.adjust {
  min-width: none;
  width: 100%;
}

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: #0088ff;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.custom-marker-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom-marker {
  background-color: #0088ff;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
  align-self: flex-start;
  cursor: pointer;
  transition: font-size 0.15s;
  white-space: nowrap;
}

.custom-marker:hover {
  font-size: 1rem;
}

.custom-marker::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #0088ff;
}

.highlighted-marker {
  font-size: 1rem;
  border: 2px solid #0088ff;
  background-color: #14161a;
  color: #0088ff;
}

.custom-marker-label {
  cursor: pointer;
  margin-top: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color: #fff;
  font-size: 0.9rem;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

/* ACCOUNT SECTION */

.login-container {
  width: 100%;
  min-width: 100%;
  height: 100%;
  padding: 2rem;
  text-align: center;
  color: #fff;
}

.login-body {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.form-group label {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
}

.form-group input::placeholder {
  color: #888;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  height: 40px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s;
}

.login-button:hover {
  background-color: #0056b3;
}

.form-footer {
  margin-top: 1rem;
}

.form-footer a {
  color: #0088ff;
  text-decoration: underline;
}

/* PROFILE */

.profile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  color: #fff;
  overflow-y: auto;
  scrollbar-width: none;
}

@media (min-width: 500px) {
  .profile-container {
    padding-top: 50px;
    position: unset;
    height: 100%;
  }
}

.profile-header {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  gap: 0.25rem;
  top: 0;
  width: 100%;
  max-width: 600px;
  height: 50px;
  background-color: #212121;
  font-size: 1.25rem;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.profile-header .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  font-size: 1.25rem;
  cursor: pointer;
}

.profile-header .icon-wrapper:hover {
  background-color: #404040;
}

.profile-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  padding: 0.75rem;
  width: 100%;
  background: rgb(33, 33, 33);
  background: linear-gradient(
    360deg,
    rgba(33, 33, 33, 1) 0%,
    rgba(0, 136, 255, 1) 100%
  );
}

.profile-info .image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #000;
  background-size: cover;
  background-position: center;
  margin-bottom: 0.5rem;
}

.profile-info .name {
  font-size: 1.5rem;
}

.profile-info .handle {
  opacity: 0.7;
}

.profile-actions-wrapper {
  padding: 0.5rem 1rem;
}

.profile-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.profile-actions .action-el {
  width: 100%;
  max-width: 300px;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.1s;
}

.profile-actions .action-el svg {
  height: 16px;
  width: 16px;
  margin-right: 0.25rem;
}

.profile-actions .message {
  background-color: #0088ff;
}

.profile-actions .message:hover {
  background-color: #0056b3;
}

.profile-actions .friend,
.profile-actions .settings {
  background-color: #444;
}

.profile-actions .friend:hover,
.profile-actions .settings:hover {
  background-color: #333;
}

.profile-content {
  height: 100%;
}

.profile-content-header {
  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 0 0.5rem;
  border-bottom: 1px solid #404040;
}

.profile-content-header a {
  width: 100%;
  padding: 0.5rem 0rem;
  text-align: center;
  cursor: pointer;
}

.profile-content-header .active {
  color: #0088ff;
  font-weight: 500;
  border-bottom: 3px solid #0088ff;
}

.profile-content-body {
  height: calc(100% - 50px);
}

.profile-spots-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.empty-profile-message {
  padding: 1rem;
  color: #fff;
  text-align: center;
}

.profile-spots-container .empty-profile-message {
  padding: 0;
}

/* SETTINGS */

.settings-container {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
}

.settings-header {
  display: flex;
  gap: 0.5rem;
  font-size: 1.5rem;
  padding: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
}

.settings-body {
  padding: 1rem;
}

.settings-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.settings-form:last-child {
  margin-top: 1rem;
}

.settings-form .btn {
  height: 40px;
  width: 176px;
  background-color: #0088ff;
  border: none;
  font-size: 1.15rem;
  border-radius: 5rem;
  color: #fff;
  align-self: center;
  cursor: pointer;
  transition: all 0.2s;
}

.form-photo {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  align-self: center;
  background-size: cover;
  background-position: center;
  border: 1px solid #0088ff;
  cursor: pointer;
}

.form-photo:hover {
  opacity: 0.9;
}

.form-photo-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;
  width: 25px;
  background-color: #404040;
  border: 1px solid #212121;
  border-radius: 100%;
}

.form-group .input-file {
  display: none;
}
/* ALERTS */

.alert {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  max-width: 500px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  background-color: #14161a;
  border-radius: 0.25rem 0 0 0.25rem;
  transition: all 0.5s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

@media (min-width: 500px) {
  .alert {
    left: calc(50% + 40px);
  }
}

@media (min-width: 1000px) {
  .alert {
    left: 50%;
  }
}

.show-alert {
  opacity: 1;
}

.hide-alert {
  opacity: 0;
  left: 100%;
  pointer-events: none;
}

.alert-content {
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #14161a;
  overflow: hidden;
}

.alert-content svg {
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.alert-content button svg {
  min-height: 2rem;
  min-width: 2rem;
  transition: color 0.2s;
}
.alert-content button:hover {
  color: #888;
}

.alert-content span {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.alert .alert-bar {
  background-color: #20bf6b;
  height: 2px;
  animation: slide 2.5s linear forwards;
}

@keyframes slide {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* Spotlist detail */

.spotlist-detail-container {
  min-width: 100%;
  height: 100%;
  overflow-y: auto;
}

@media (min-width: 1000px) {
  .spotlist-detail-container {
    display: flex;
  }

  .spotlist-detail-header {
    max-width: 360px;
    width: 100%;
  }
}

.spotlist-detail-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: rgb(33, 33, 33);
  background: linear-gradient(
    360deg,
    rgba(33, 33, 33, 1) 0%,
    rgba(0, 136, 255, 1) 100%
  );
  padding: 1rem;
  padding-top: 0.5rem;
  color: #fff;
}

.spotlist-detail-header .svg-wrapper {
  font-size: 1.35rem;
}

.spotlist-detail-header-cover {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
}

.spotlist-detail-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spotlist-detail-title {
  line-height: 1.3;
  font-size: 1.5rem;
  font-weight: 700;
}

.spotlist-detail-author {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.spotlist-detail-author:hover {
  text-decoration: underline;
}

.spotlist-detail-author .image {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
}

.spot-detail-details {
  font-size: 0.9rem;
  font-weight: 500;
}

.spotlist-detail-title,
.spotlist-detail-description,
.spotlist-detail-spots-el .name,
.spotlist-detail-spots-el .location {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spotlist-detail-description {
  font-size: 0.8rem;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  line-height: 1.3;
}

.spotlist-detail-options {
  display: flex;
  gap: 1rem;
}

.spotlist-detail-menu button,
.spotlist-detail-options-el {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: #333;
  color: #0088ff;
  transition: background-color 0.2s;
  cursor: pointer;
}

.spotlist-detail-menu button:hover,
.spotlist-detail-options-el:hover {
  background-color: #404040;
}

.spotlist-detail-options-el.active {
  color: #fa507d;
}

.spotlist-detail-menu {
  margin-left: auto;
}

@media (min-width: 500px) {
  .spotlist-detail-menu {
    position: relative;
  }

  .spotlist-detail-spots-el .menu {
    position: relative;
  }
}

.spotlist-detail-spots {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border-top: 1px solid #333;
  padding: 1rem;
}

.spotlist-detail-spots-el {
  position: relative;
  display: grid;
  grid-template-columns: 178px 1fr auto;
  gap: 0.5rem;
  width: 100%;
  color: #fff;
}

.spotlist-detail-spots-el:not(:has(.options)) {
  grid-template-columns: fit-content 1fr;
}

.spotlist-detail-spots-el .image {
  height: 100%;
  aspect-ratio: 16/9;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
  width: 178px;
  height: 100px;
}

.spotlist-detail-spots-el .info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  line-height: 1.2;
}

.spotlist-detail-spots-el .location {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  font-size: 0.75rem;
  opacity: 0.6;
}

.spotlist-detail-spots-el .location svg {
  margin-right: 0.25rem;
}

.spotlist-detail-spots-el .options {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  transition: background-color 0.2s;
}

.spotlist-detail-spots-el .options:hover {
  background-color: #333;
}

@media (min-width: 850px) {
  .spotlist-detail-spots-el {
    grid-template-columns: 178px 1fr auto;
  }
}

.spotlist-detail-error {
  height: 100%;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  color: #fff;
}

.spotlist-detail-error button {
  padding: 0.5rem 1rem;
  background-color: #0088ff;
  border-radius: 1rem;
}

.spotlist-detail-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 1rem;
  font-size: 1.25rem;
}

/* Messages  */

.messages-and-chat-container {
  display: flex;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
}

.messages-container,
.chat-section {
  flex: 1;
}

.start-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  height: 100%;
}

@media (max-width: 999px) {
  .messages-container.hidden {
    transform: translateX(-100%);
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: white;
  }

  .chat-section.hidden {
    transform: translateX(100%);
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: white;
  }
}

@media (min-width: 1000px) {
  .messages-and-chat-container {
    flex: 1;
  }

  .chat-section {
    flex: 1.5;
  }

  .message-container.hidden,
  .chat-section.hidden {
    transform: none;
    position: relative;
  }

  .messages-container {
    border-right: 1px solid #007bff;
  }
}

.messages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  overflow: hidden;
}

.messages-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
}

.messages-menu {
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border-radius: 100%;
  margin-right: 0.75rem;
  border: 1px solid #0088ff;
  color: #0088ff;
}

.messages-nav h2 {
  margin: 0;
  font-weight: 500;
}

.contact-request-info {
  margin: 1rem 0rem;
}

.new-messages {
  font-weight: 700;
}

.messages-chats {
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
}

.messages-chats-el {
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: 70px;
  width: 100%;
}

.messages-chats-el-img {
  height: 100%;
  min-width: 70px;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  border: 1px solid #0088ff;
  position: relative;
}

.messages-chats-el-img .online-bubble {
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.15rem #14161a solid;
  background-color: #20bf6b;
}

.messages-chats-el-img .offline-bubble {
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.15rem #14161a solid;
  background-color: gray;
}

.messages-chats-el-info {
  display: grid;
  grid-template-areas:
    "name unread-messages"
    "message time";
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100%;
}

.info-name {
  font-size: 1.1rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: name;
}

.info-undread-messages {
  display: flex;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  margin-left: auto;
  background-color: #0088ff;
  font-size: 0.75rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  align-self: center;
  grid-area: unread-messages;
}

.info-message {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min-content;
  grid-area: message;
}

.info-time {
  text-align: right;
  min-width: fit-content;
  grid-area: time;
}

.messages-container-empty {
  text-align: center;
  padding: 0.5rem;
}

/* Chat  */

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 500px) {
  .chat-container {
    position: absolute;
    height: calc(100%);
    width: 100%;
    z-index: 10;
    bottom: 0;
  }
}

.chat-header {
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #14161a;
  gap: 0.5rem;
  padding: 1rem;
  font-size: 1.25rem;
  border-bottom: 1px solid #007bff;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.65);
  overflow: hidden;
}

.chat-header-img {
  height: 45px;
  min-width: 45px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  position: relative;
}

.chat-header-info .name {
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-header-img:hover + .chat-header-info .name,
.chat-header-info .name:hover {
  text-decoration: underline;
}

.chat-header-info {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
}

.chat-header-img .online-bubble {
  position: absolute;
  height: 12px;
  width: 12px;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  border: 0.15rem #14161a solid;
  background-color: #20bf6b;
}

.chat-messages {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 0.5rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.chat-messages::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chat-messages-not-approved {
  color: #fff;
  text-align: center;
  padding: 0 1rem;
}

.chat-messages-time {
  text-align: center;
  background-color: #333333;
  color: #fff;
  width: fit-content;
  margin: 1rem auto;
  padding: 0.2rem 2rem;
  font-size: 0.9rem;
  border-radius: 1rem;
}

.chat-bubble {
  color: #fff;
  background-color: #333;
  max-width: 75%;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  word-break: break-word;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.first-of-friend {
  margin-top: 0.75rem;
  position: relative;
  border-radius: 0px 0.5rem 0.5rem 0.5rem;
}

.first-of-friend::before {
  content: "";
  position: absolute;
  top: 0;
  left: -6px;
  border-top: 10px solid #333;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.first-of-me {
  margin-top: 0.75rem;
  position: relative;
  border-radius: 0.5rem 0px 0.5rem 0.5rem;
}

.first-of-me::before {
  content: "";
  position: absolute;
  top: 0;
  right: -6px;
  border-top: 10px solid #0088ff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.chat-bubble p {
  margin: 0;
}

.message-time {
  opacity: 0.7;
  font-size: 0.75rem;
  text-align: right;
}

.message-read {
  margin-left: 0.25rem;
  font-size: 0.85rem;
  opacity: 1;
}

.message-not-read {
  font-size: 0.85rem;
  margin-left: 0.25rem;
  opacity: 0.5;
}

.your-message {
  align-self: flex-end;
  background-color: #0088ff;
  text-align: right;
}

.chat-input-area {
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
  background-color: #212121;
}

.chat-input {
  border-radius: 2rem;
  padding-left: 1.25rem;
  font-size: 1.2rem;
  width: 100%;
  background-color: #404040;
  color: #fff;
  border: none;
}

.send-button {
  background-color: #0088ff;
  border: 1px solid black;
  font-size: 1.25rem;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  cursor: pointer;
}

/* Chat Search Bar */

.chat-search-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
  background-color: #212121;
  color: #fff;
  z-index: 10;
}

@media (min-width: 500px) {
  .chat-search-container {
    position: unset;
    height: 100%;
  }
}

.chat-search-header {
  display: flex;
  align-items: center;
  background-color: #14161a;
  padding: 1rem;
  border-bottom: 1px solid #0088ff;
  gap: 0.5rem;
}

.chat-search-header svg {
  font-size: 1.5rem;
}

.chat-search-header input {
  color: #fff;
  width: 100%;
  padding: 0.5rem 0.5rem;
  background-color: #212121;
  border-radius: 0.5rem;
  border: 1px solid #444;
  outline: none;
  transition: border 0.2s;
}

.chat-search-header input:focus {
  border: 1px solid #0088ff;
}

.chat-search-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  transition: all 0.2s;
}

.chat-search-header button:hover {
  background-color: #333;
}

.chat-search-header button.active {
  opacity: 1;
}

.chat-search-header button.inactive {
  opacity: 0.25;
  pointer-events: none;
}

.chat-search-results {
  padding: 0.5rem 1rem;
  overflow: hidden;
}

.chat-search-results-title {
  opacity: 0.6;
  font-weight: 500;
}

.chat-search-results-el {
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.2s;
}

.chat-search-results-el:hover {
  background-color: #333;
}

.chat-search-results-el-img {
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

.chat-search-results-el-info > div {
  white-space: nowrap;
}

.chat-search-results-el-name {
  font-weight: 500;
  text-overflow: ellipsis;
}

.chat-search-results-el-handle {
  font-size: 0.85rem;
  opacity: 0.7;
  font-weight: 300;
}

.chat-search-results-group {
  margin-top: 1rem;
}

/* FRIENDS  */

.friends-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  color: #fff;
  width: 100%;
}

.friends-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
}

.social-nav {
  display: grid;
  grid-template-columns: auto auto 50px;
  gap: 0.5rem;
  padding: 1rem;
}

.social-nav-el {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282828;
  border-radius: 1rem;
  padding: 0.5rem;
  text-align: center;
}

.social-nav-el.active {
  outline: 1px solid #007bff;
  color: #007bff;
}

.social-nav-el.search {
  background-color: #14161a;
  color: #0088ff;
  border: 1px solid #0088ff;
}

.friends-body {
  height: 100%;
  width: 100%;
  padding: 0rem 1rem;
}

.friends-body-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 500px) {
  .friends-body-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.friends-count {
  font-weight: 500;
  grid-column: 1/-1;
}

.friend-el {
  width: 100%;
  display: grid;
  grid-template-columns: 55px auto;
  gap: 0.5rem;
  align-items: center;
}

.friend-el-img {
  position: relative;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

.friend-el-status {
  position: absolute;
  right: 2px;
  bottom: 2px;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 100%;
}

.friend-el-info {
  line-height: 1.2;
  overflow: hidden;
}

.friend-el-info p {
  margin: 0;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.friend-el-info p:last-child {
  opacity: 0.7;
  font-size: 0.9rem;
  font-weight: 300;
}

.friends-container-empty {
  text-align: center;
}

/* FRIEND REQUEST */

.friend-request-el {
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.5rem;
  align-items: center;
}

.friend-request-el-img {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: #0088ff;
  background-position: center;
  background-size: cover;
  grid-row: span 2;
}

.friend-request-el-info {
  line-height: 1.2;
  overflow: hidden;
}

.friend-request-el-info p {
  margin: 0;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.friend-request-el-info p:last-child {
  opacity: 0.7;
  font-size: 0.9rem;
  font-weight: 300;
}

.friend-request-el-options {
  display: flex;
  gap: 0.5rem;
}

.friend-request-el-options button {
  width: 100%;
  color: #fff;
  height: 35px;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.friend-request-el-options .accept {
  background-color: #007bff;
}

.friend-request-el-options .accept:hover {
  background-color: #0056b3;
}

.friend-request-el-options .delete {
  border: 1px solid #d5372f;
  color: #d5372f;
}

.friend-request-el-options .delete:hover {
  background-color: rgba(213, 55, 47, 0.2);
}

@media (min-width: 500px) {
  .friend-request-el {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    background-color: #282828;
    padding: 1rem;
    border-radius: 1rem;
    text-align: center;
  }

  .friend-request-el-img {
    justify-self: center;
  }

  .friend-request-el-options {
    flex-direction: column;
  }
}

/* POSTS */

.posts-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  max-width: 600px;
}

.posts-container button {
  background-color: inherit;
  border: none;
  color: inherit;
  font-size: inherit;
}

.post-info {
  width: 100%;
}

.post-sections {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #404040;
  color: #fff;
}

.post-sections .section-el {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s;
}

.post-sections .section-el.active span {
  display: inline-block;
  position: relative;
}

.post-sections .section-el.active span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: #0088ff;
}

.post-sections .section-el:hover {
  background-color: #404040;
}

.post-add-box {
  display: flex;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #404040;
  gap: 0.5rem;
}

.profile-icon {
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
}

.post-add-div {
  width: 100%;
  color: #fff;
  background-color: #404040;
  border-radius: 2rem;
  place-content: center;
  padding-left: 1rem;
  cursor: pointer;
}

.posts-body {
  height: 100%;
}

.posts-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 1px;
  gap: 1px;
  background-color: #404040;
}

.post {
  display: flex;
  padding: 0.75rem 1rem;
  color: #fff;
  gap: 0.5rem;
  transition: all 0.2s;
  background-color: #212121;
  white-space: pre-wrap;
}

.post:hover {
  background-color: #282828;
}

.post:has(.aviable-options:hover) {
  background-color: #212121;
}

.post-header {
  display: grid;
  grid-template-columns: auto 35px;
  align-items: center;
  gap: 0.25rem;
}

.post-user-info {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.post .username {
  font-weight: 500;
  flex-shrink: 0;
  overflow: hidden;
}

.post .username:hover {
  text-decoration: underline;
}

.post .handle {
  flex-shrink: 100;
  overflow: hidden;
}

.post .handle,
.post .timestamp {
  opacity: 0.5;
  font-weight: 300;
  margin-left: 0.25rem;
}

@media (min-width: 500px) {
  .post-options {
    position: relative;
  }
}

.svg-wrapper {
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  transition: all 0.2s;
  cursor: pointer;
}

.svg-wrapper:hover {
  background-color: #333;
}

.svg-wrapper.bookmarked {
  color: #0088ff;
}

.post-content {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.post-content-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.post-footer {
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
}

.footer-el {
  display: flex;
  align-items: center;
}

.footer-el .liked {
  color: #fa507d;
}

.footer-el svg {
  font-size: 1.1rem;
}

.footer-el:first-child {
  margin-left: -0.5rem;
}

.footer-el:last-child {
  margin-left: auto;
}

.post-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #000;
  z-index: 9;
  top: 0;
}

.empty-posts-message {
  padding: 1rem;
  color: #fff;
  text-align: center;
}

.post-fetch-info {
  grid-column: 1/-1;
  width: 100%;
  padding: 1rem;
  color: #fff;
  text-align: center;
  background-color: #282828;
}

/* AVIABLE OPTIONS  */

.aviable-options {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000;
  z-index: 15;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1.5rem 1.5rem 0 0;
}

.aviable-options .option {
  padding: 1rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.1s;
}

.aviable-options .option svg {
  width: 16px;
}

.aviable-options .option.delete {
  color: red;
}

.aviable-options .option:hover {
  background-color: #212121;
}

.aviable-options button {
  width: calc(100% - 2rem);
  margin: 0.5rem auto;
  align-self: center;
  border: 1px solid #888;
  font-weight: 700;
  height: 45px;
  border-radius: 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: inherit;
  color: #fff;
}

.aviable-options button:hover {
  background-color: #14161a;
}

.options-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 10;
}

@media (min-width: 500px) {
  .aviable-options {
    position: absolute;
    display: block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transform: translate(-165px, -30px);
    z-index: 11;
    border-radius: 0;
    inset: unset;
    width: 200px;
  }

  .aviable-options .option {
    background-color: #181818;
  }

  .aviable-options button {
    display: none;
  }

  .options-overlay {
    opacity: 0;
  }
}

/* POST CREATE  */

.post-create {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 11;
  background: #212121;
  color: #fff;
  overflow-y: auto;
}

@media (min-width: 500px) {
  .post-create {
    height: unset;
    min-height: 400px;
    max-height: 100dvh;
    border-radius: 2rem;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.post-create-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.post-create-body {
  display: grid;
  align-items: center;
  grid-template-columns: 40px auto;
  column-gap: 0.5rem;
}

.post-create-body .profile-icon {
  grid-row: span 2;
}

.post-create-body span {
  font-weight: 500;
  font-size: 0.9rem;
}

.post-create-input-wrapper {
  position: relative;
  display: grid;
  gap: 1rem;
  min-height: 100px;
  margin-top: 0.5rem;
  grid-column: span 2;
}

.post-create-input {
  height: fit-content;
  outline: none;
  border: none;
  field-sizing: content;
  font-size: 1.1rem;
  background-color: #212121;
  color: inherit;
  max-height: 150px;
  overflow-y: auto;
}

@media (min-width: 500px) {
  .post-privacy-control {
    position: relative;
  }

  .post-privacy-control .aviable-options {
    transform: translate(0px, 10px);
  }

  .privacy-overlay {
    opacity: 0 !important;
  }
}

.post-create-privacy {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #0088ff;
}

.post-create-options {
  color: #0088ff;
  border-top: 1px solid #0088ff;
  padding-top: 0.25rem;
}

.post-create-options .option {
  margin-top: 0.25rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1rem auto;
  gap: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
}

.post-create-options input {
  display: none;
}

.post-create-options .option svg {
  display: block;
  margin: 0 auto;
}

.post-create-btn {
  margin-top: 0.5rem;
  width: 100%;
  min-height: 48px;
  color: inherit;
  font-size: 1.15rem;
  background-color: #0088ff !important;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.post-create-btn:hover {
  background-color: #0056b3 !important;
}

.post-create-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
  z-index: 10;
}

.post-create-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
  z-index: 10;
}

.privacy-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
}

/* POST DETAIL  */

.post-detail-container {
  color: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

@media (max-width: 499px) {
  .post-detail-container {
    position: absolute;
    height: 100dvh;
    width: 100%;
    z-index: 10;
    bottom: 0;
  }
}

.post-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #14161a;
  padding: 0 0.75rem;
  min-height: 50px;
  border-bottom: #0088ff 1px solid;
}

.post-detail-header span {
  margin: 0 auto;
}

.post-detail-body {
  height: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  scrollbar-width: none;
}

.post-detail-author {
  display: grid;
  align-items: center;
  column-gap: 0.5rem;
  grid-template-columns: 40px auto 40px;
  line-height: normal;
}

.post-detail-author .profile-icon {
  grid-row: span 2;
}

.post-detail-author-info {
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-detail-author .username {
  font-weight: 500;
}

.post-detail-author .username:hover {
  text-decoration: underline;
}

.post-detail-author .handle {
  font-size: 0.85rem;
  opacity: 0.6;
  font-weight: 300;
}

.post-options {
  margin-left: auto;
}

.post-detail-date {
  opacity: 0.6;
  font-weight: 300;
}

.post-detail-options {
  display: flex;
  gap: 0.5rem;
  background-color: #212121;
  margin-bottom: 0.5rem;
}

.post-detail-options .option {
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
}

.post-detail-options .liked,
.comment-option-like .liked {
  color: #fa507d;
}

.post-detail-comment {
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: 20px 20px auto;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-areas:
    "icon info options"
    "icon content options"
    "icon content ."
    "icon commentoptions ."
    "icon viewreplies ."
    "icon replies replies";
}

.post-detail-comment.active {
  background-color: #282828;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-right: 0;
}

.comment-header {
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
}

.comment-header .user-name {
  flex-shrink: 1;
  overflow: hidden;
}

.comment-header .user-name:hover {
  text-decoration: underline;
}

.comment-header .user-handle {
  flex-shrink: 100;
  overflow: hidden;
}

.comment-header .is-edited {
  flex-shrink: 50;
  overflow: hidden;
}

.comment-header .timestamp {
  flex-shrink: 1;
  overflow: hidden;
}

.comment-header span {
  opacity: 0.6;
  font-weight: 300;
}

.post-detail-comment .profile-icon {
  grid-area: icon;
  align-self: flex-start;
}

.post-detail-body .options {
  grid-area: options;
}

.post-detail-comment .options:hover {
  background-color: #333;
}

.comment-content {
  grid-area: content;
  word-break: break-word;
}

.comment-options {
  grid-area: commentoptions;
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.comment-option-like {
  display: flex;
  align-items: center;
}

.comment-option-like .svg-wrapper {
  min-width: 35px;
  margin-left: -0.5rem;
}

.comment-option-reply {
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  transition: all 0.2s;
}

.comment-option-reply:hover {
  background-color: #333;
}

.view-comment-replies {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  color: #0088ff;
  font-weight: 500;
  grid-area: viewreplies;
}

.post-detail-comment-replies {
  grid-area: replies;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.post-detail-your-comment {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  background-color: blue;
  z-index: 2;
  width: 100%;
}

.post-detail-your-comment .reply-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0.5rem;
  background-color: #0088ff;
  height: 50px;
  width: 100%;
  transition: all 0.2s;
}

.post-detail-your-comment .reply-info .svg-wrapper {
  font-size: 1.5rem;
}

.post-detail-your-comment .reply-info .svg-wrapper:hover {
  background-color: inherit;
}

.post-detail-your-comment .input-wrapper {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0.75rem;
  background-color: #333333;
  align-items: center;
}

.post-detail-your-comment textarea {
  background-color: #333333;
  border: none;
  outline: none;
  padding: 0.75rem 0.5rem;
  field-sizing: content;
  font-size: 1rem;
  max-height: 80px;
  color: inherit;
  width: 100%;
  transition: height 0.2s;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: border 0.2s;
}

.post-detail-your-comment textarea:focus {
  border: 1px solid #0088ff;
}

.post-detail-your-comment textarea::placeholder {
  white-space: nowrap;
  overflow: hidden;
}

.post-comment-btn {
  height: 42px;
  width: 80px;
  background-color: #0088ff;
  border: none;
  color: inherit;
  font-size: 1rem;
  border-radius: 1.5rem;
  transition: all 0.2s;
  cursor: pointer;
}

.handle-highlight {
  color: #0088ff;
  font-weight: 500;
}

.post-detail-comments-empty {
  border-top: 1px solid #404040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 0;
  font-size: 1.25rem;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.post-detail-comments-empty svg {
  font-size: 2rem;
}

/* POST TAGGING POPUP */

.post-tagging-container {
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #14161a;
  height: 290px;
  width: 100%;
  top: 100%;
  overflow-y: auto;
  line-height: 1;
  z-index: 10;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.post-tag-el {
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 0.5rem;
  align-items: center;
  padding: 0.75rem 1rem;
}

.post-tag-el .profile-icon {
  grid-row: span 2;
}

.post-tag-el:hover {
  background-color: #0056b3;
  cursor: pointer;
}

.post-tag-name {
  font-weight: 500;
}

.post-tag-handle {
  opacity: 0.6;
}

.post-tagging-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-size: 1.25rem;
}

.post-detail-your-comment .post-tagging-container {
  position: relative;
  top: 0;
}

/* POST IMAGE CAROUSELL  */

.preview-container {
  position: relative;
  overflow: hidden;
}

.profile-posts-container .preview-container,
.posts-container .preview-container {
  margin-top: 0.5rem;
}

.image-previews {
  display: flex;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  transform: translateX(0%);
  align-items: stretch;
  border-radius: 2rem;
  transition: transform 0.2s;
}

.image-previews-el {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.image-preview-fade {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  filter: blur(12px);
}

.image-previews-el img {
  max-height: 400px;
  z-index: 1;
}

.image-preview-controls {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: grid;
  top: 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "image-counter . delete-image"
    "swipe-left . swipe-right"
    ".  current-image .";
  align-items: center;
  padding: 0.5em;
  user-select: none;
  overflow: hidden;
}

.control-swipe {
  background-color: #000;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  cursor: pointer;
}

.control-swipe.left {
  grid-area: swipe-left;
}

.control-swipe.right {
  grid-area: swipe-right;
  margin-left: auto;
}

.current-image {
  position: relative;
  grid-area: current-image;
  margin: auto auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.25rem;
  gap: 0.35rem;
  border-radius: 0.5rem;
}

.current-image::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: #000;
  border-radius: 0.5rem;
  opacity: 0.55;
  z-index: -1;
}

.current-image .dot {
  background-color: #fff;
  opacity: 0.3;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  transition: opacity 0.5s;
}

.current-image .dot.active {
  opacity: 1;
}

.control-swipe.delete {
  grid-area: delete-image;
  margin-left: auto;
  margin-bottom: auto;
}

.image-counter {
  grid-area: image-counter;
  margin-right: auto;
  margin-bottom: auto;
  background-color: #000;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* POST SPOTS */

.post-spots-container {
  display: grid;
  gap: 0.1rem;
  height: 240px;
  grid-template-columns: 1fr;
}

.post-spots-container .spot-el {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid #0088ff;
  border-radius: 0.5rem;
  background-position: center;
  background-size: cover;
}

.post-spots-container .spot-el::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.25) 35%,
    rgba(0, 0, 0, 0.25) 65%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.post-spots-container:has(.spot-el:nth-child(2)) {
  grid-template-columns: 1fr 1fr;
}

.post-spots-container:has(.spot-el:nth-child(3)) {
  height: 260px;
  grid-template-columns: 1fr 1fr;
}
.post-spots-container:has(.spot-el:nth-child(3)) .spot-el:first-child {
  grid-row: span 2;
}

.post-spots-container:has(.spot-el:nth-child(4)) {
  height: 260px;
  grid-template-columns: 1fr 1fr;
}

.post-spots-container:has(.spot-el:nth-child(4)) .spot-el:first-child {
  grid-row: span 1;
}

.post-spots-container:has(.spot-el:nth-child(5)) {
  height: 320px;
  grid-template-columns: 1fr 1fr;
}
.post-spots-container:has(.spot-el:nth-child(5)) .spot-el:first-child,
.post-spots-container:has(.spot-el:nth-child(5)) .spot-el:nth-child(4) {
  grid-row: span 3;
}
.post-spots-container:has(.spot-el:nth-child(5)) .spot-el:nth-child(2),
.post-spots-container:has(.spot-el:nth-child(5)) .spot-el:nth-child(3),
.post-spots-container:has(.spot-el:nth-child(5)) .spot-el:nth-child(5) {
  grid-row: span 2;
}

.post-spots-container .spot-el-info {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-template-columns: auto 35px;
  height: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  width: 100%;
}

.post-spots-container .spot-el-info div {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spot-el-info-upper {
  display: flex;
  justify-content: space-between;
}

.spot-el-info .spot-el-location svg {
  margin-right: 0.25rem;
  color: #0088ff;
}

.post-spots-container .spot-el-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: #000;
  cursor: pointer;
  border-radius: 100%;
  opacity: 0.8;
}

.post-spotlists-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.post-spotlist-el {
  position: relative;
  display: grid;
  grid-template-columns: 120px auto;
  height: 90px;
  width: 100%;
  border: 1px solid #0088ff;
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.2s;
}

.post-spotlist-img {
  position: relative;
  background-position: center;
  background-size: cover;
  min-width: 120px;
  height: 100%;
}

.post-spotlist-img .spotlists-spot-count {
  font-size: 0.75rem;
}

.post-spotlist-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.9rem;
  padding: 0.5rem;
  line-height: 1.3;
  overflow: hidden;
}

.post-spotlist-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: block;
}

.post-spotlist-visibility {
  font-size: 0.75rem;
}

.post-spotlist-description {
  display: inline-block;
  font-size: 0.75rem;
  opacity: 0.7;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}

.post-spotlist-delete {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #000;
  font-size: 0.9rem;
  cursor: pointer;
}

/* Post Add Spots  */

.post-add-spots-container {
  position: absolute;
  display: grid;
  grid-template-rows: 35px auto 35px;
  row-gap: 1rem;
  padding: 1rem;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #212121;
}

.post-create-header button {
  margin-left: auto;
  background-color: #0088ff;
  padding: 0.3rem 1rem;
  color: #000;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.post-add-spots-body {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.post-create-add-spots {
  display: flex;
  width: 100%;
  transition: all 0.3s;
  scrollbar-width: none;
}

.pick-spotlist {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 100%;
  height: 100%;
  padding: 0.25rem;
  overflow-y: auto;
  scrollbar-width: none;
}

.pick-spotlist .spotlist-el,
.pick-spot .spot-el {
  display: flex;
  gap: 0.5rem;
  min-height: 90px;
  line-height: 1.4;
  cursor: pointer;
}

.pick-spotlist .spotlist-el .photo {
  position: relative;
  width: 40%;
  max-width: 140px;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: green;
  border-radius: 0.5rem;
  flex-shrink: 0;
}

.pick-spotlist .spotlist-el .info {
  overflow: hidden;
  line-height: 1.5;
}

.pick-spotlist .no-wrap,
.pick-spot .no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pick-spotlist .spotlist-el .visibility,
.pick-spot .spot-el .address {
  font-size: 0.75rem;
}

.pick-spotlist .spotlist-el .description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.75rem;
  opacity: 0.7;
}

.pick-spotlist .pick-btn {
  font-size: 1.5rem;
  margin-left: auto;
}

.pick-spot {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 100%;
  height: 100%;
  padding: 0.25rem;
  overflow-y: auto;
  scrollbar-width: none;
}

.pick-spot .spot-el {
  display: flex;
  overflow: hidden;
  transition: all 0.2s;
}

.pick-spot .spot-el .photo {
  max-width: 140px;
  min-width: 140px;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
}

.pick-spot .spot-el .info {
  width: 100%;
  overflow: hidden;
}

.post-add-spots-footer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.post-add-spots-footer button.active {
  opacity: 1;
  pointer-events: unset;
}

.post-add-spots-footer button {
  width: 100%;
  border: 1px solid #0088ff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  opacity: 0.5;
  pointer-events: none;
  transition: all 0.2s;
}

.post-add-spotlists-footer button.active {
  opacity: 1;
  pointer-events: unset;
}

.pick-spots-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem 0;
}

/* POST ADD SPOTLISTS  */

.post-add-spotlists-container {
  position: absolute;
  display: grid;
  grid-template-rows: 35px auto 35px;
  row-gap: 1rem;
  padding: 1rem;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: #212121;
  border-radius: 2rem;
  z-index: 1;
}

.post-add-spotlists-container .counter,
.post-add-spots-container .counter {
  margin-left: auto;
  background-color: #0088ff;
  color: #000;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
}

.post-add-spotlists-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.25rem;
  overflow-y: auto;
  height: 100%;
  scrollbar-width: none;
}

.post-add-spotlists-body .post-spotlist-el {
  min-height: 90px;
  cursor: pointer;
  grid-template-columns: 120px auto 50px;
}

.post-create .post-spotlist-check {
  align-self: center;
  justify-self: center;
}

.post-create .post-spotlist-check input {
  height: 20px;
  width: 20px;
}

.post-add-spotlists-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-add-spotlists-footer button {
  width: 75%;
  border: 1px solid #0088ff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  opacity: 0.5;
  pointer-events: none;
  transition: all 0.2s;
}

.post-add-spotlists-footer button.active {
  opacity: 1;
  pointer-events: unset;
}

.post-add-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
}

/* NOTIFICATIONS */

.notifications-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  width: 100%;
}

.notifications-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
}

.notifications-body {
  height: 100%;
}

.notifications-content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 1px;
  background-color: #333;
}

.notification-el {
  display: grid;
  background-color: #212121;
  grid-template-columns: 5px 35px auto 35px;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  padding-left: 0.1rem;
  width: 100%;
  line-height: 1.4;
  cursor: pointer;
}

.notification-el:not(:has(.image)) {
  grid-template-columns: 5px auto 35px;
}

.notification-el:not(:has(.aviable-options)):hover {
  background-color: #282828;
}

.notification-el .new {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #0088ff;
}

.notification-el .new.old {
  background-color: #212121;
}

.notification-el .info {
  overflow: hidden;
}

.notification-el .info-summary span {
  opacity: 0.7;
  font-weight: 300;
  font-size: 0.9rem;
  white-space: nowrap;
}

.notification-el .info-details {
  font-size: 0.9rem;
  opacity: 0.7;
  font-weight: 300;
  overflow: hidden;
}

.notification-el .image {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  align-self: flex-start;
}

.notification-el .options-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  align-self: flex-start;
}

.notification-el .options-button:hover {
  background-color: #333;
}

@media (min-width: 500px) {
  .notification-el .options {
    position: relative;
  }
}

.empty-notifications-message {
  padding: 1rem;
  text-align: center;
}

/* Discover  */

.discover-container {
  max-width: 600px;
  height: 100%;
  color: #fff;
}

.discover-header {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  border-bottom: 1px solid #404040;
}

.discover-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.discover-el {
  gap: 1rem;
  text-align: center;
  background-color: #333;
  padding: 1rem;
  color: #fff;
  user-select: none;
  border-radius: 1rem;
  transition: all 0.2s;
  cursor: pointer;
}

.discover-el:hover {
  background-color: #282828;
}

.discover-el-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.discover-el-description {
  font-size: 0.85rem;
}

/* Area Search */

.area-search-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 0.5rem;
  color: #fff;
  transition: all 0.4s;
}

.area-search-header {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  border-bottom: 1px solid #404040;
}

.area-search-body {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.5rem 1rem 0rem;
  height: 100%;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-header .icon {
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.filter-header p {
  text-decoration: underline;
  cursor: pointer;
}

.category-box {
  display: block;
  margin-top: 0.5rem;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #fff;
  background-color: #14161a;
  cursor: pointer;
  color: #fff;
}

.category-box-textarea {
  display: block;
  margin-top: 0.5rem;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 1rem;
  border: 1px solid #fff;
  background-color: #14161a;
  cursor: pointer;
  color: #fff;
  max-height: 98px;
  field-sizing: content;
  scrollbar-width: none;
}

.search-spots-btn {
  margin-top: auto;
  width: 100%;
  height: 50px;
  background-color: #0088ff;
  border: none;
  color: #14161a;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

/* Slider  */

.slideContainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;
  background: linear-gradient(90deg, #0088ff 60%, rgb(214, 214, 214) 60%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 12px;
  box-shadow: 0px 1px 10px 1px black;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-webkit-slider-thumb,
.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.slider-values-label {
  display: flex;
  justify-content: space-between;
}

.slider-values-label p {
  padding: 0;
  margin: 0;
}

[role="listbox"] {
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  border: 1px solid #0088ff;
}

[role="listbox"] > [role="option"] {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background-color: #14161a;
}

[role="listbox"] > [role="option"]:hover {
  background-color: #212121;
  color: #0088ff;
}

.results-container {
  position: relative;
  height: 100%;
  min-width: 100%;
}

.results-content-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  transition: transform 0.2s;
}

.results-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-width: 100%;
  padding-bottom: 4rem;
  overflow-y: auto;
}

.results-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: #fff;
  padding: 1rem;
  border-bottom: 1px solid #404040;
}

.results-header .return-btn {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  transform: all 0.2s;
}
.results-header .return-btn:hover {
  background-color: #14161a;
}

.results-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.result-el {
  display: grid;
  grid-template-columns: 120px auto;
  width: 100%;
  border: 1px solid #0088ff;
  background-color: #14161a;
  border-radius: 1rem;
  cursor: pointer;
  color: #fff;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  transition: box-shadow 0.2s;
  font-size: 0.9rem;
}

.result-el.highlight {
  border: 2px solid #0088ff;
  box-shadow: 0 0 10px rgba(0, 136, 255, 0.7);
  background-color: #000e1a;
  transform: scale(1.03);
  transition: all 0.2s ease-in-out;
}

.result-el:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.result-el .nowrap-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-el .image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  height: 100%;
  background-color: red;
  margin-right: 0.5rem;
  grid-row: span 4;
  background-size: cover;
  background-position: center;
  border-radius: 1rem;
}

.result-el .title {
  font-size: 1.25rem;
  font-weight: 500;
}

.result-el .row-2 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.results-map {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

.results-container .switch-btn {
  position: absolute;
  background-color: #14161a;
  color: #0088ff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid #0088ff;
  left: 50%;
  min-width: 135px;
  bottom: 1rem;
  transform: translateX(-50%);
  transition: all 0.2s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

@media (min-width: 1000px) {
  .results-items,
  .results-map {
    width: 50%;
    min-width: unset;
  }

  .results-items {
    padding-bottom: 1rem;
  }

  .results-map {
    border-left: 1px solid #0088ff;
  }

  .results-container .switch-btn {
    display: none;
  }
}

.results-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
}

.results-empty-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  line-height: 1;
}

.results-empty-content svg {
  font-size: 5rem;
  color: #0088ff;
}

.results-empty-main-msg {
  font-weight: 500;
  font-size: 1.5rem;
}

.results-empty-secondary-msg {
  opacity: 0.7;
}

/* SPOT CREATE */

.create-spot-container {
  position: relative;
  height: 100%;
  width: 100%;
  color: #fff;
}

.create-spot-header {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  border-bottom: 1px solid #404040;
}

.create-spot-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 1rem;
  color: #fff;
}

.create-spot-body {
  margin-top: 0.5rem;
  height: 100%;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mini-map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000e1a;
  color: #0088ff;
  min-height: 150px;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid #0088ff;
  cursor: pointer;
  overflow: hidden;
}

.marker {
  width: 22px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -11px;
  background: url("https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png");
  background-size: 22px 40px;
  pointer-events: none;
}

.create-spot-cover-label {
  font-weight: 500;
  color: #0088ff;
  border-radius: 1rem;
  cursor: pointer;
}

.create-spot-cover-label.active {
  font-weight: normal;
  color: #fff;
  pointer-events: none;
}

.create-spot-cover input {
  display: none;
}

.create-spot-image-preview {
  position: relative;
  width: 100%;
  min-height: 200px;
  border: 1px solid #0088ff;
  border-radius: 1rem;
  overflow: hidden;
}

.image-preview {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.create-spot-image-preview .delete {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  height: 35px;
  width: 35px;
  background-color: #000;
  border-radius: 50%;
  opacity: 0.8;
}

.create-spot-btn {
  font-size: 1rem;
  min-height: 50px;
  border-radius: 1rem;
  background-color: #0088ff;
  transition: background-color 0.2s;
}

.create-spot-btn:hover {
  background-color: #0056b3 !important;
}

.create-spot-map {
  position: fixed;
  height: calc(100% - 100px);
  width: 100%;
  left: 0;
  top: 50px;
  bottom: 50px;
  scale: 0.75;
  pointer-events: none;
  transition: all 0.3s;
  opacity: 0;
}

@media (min-width: 500px) {
  .create-spot-map {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-width: 600px;
  }
}

.create-spot-map.visible {
  opacity: 1;
  pointer-events: auto;
  scale: 1;
}

.map {
  height: 100%;
  width: 100%;
}

.create-spot-map button {
  position: absolute;
  background-color: #14161a;
  border: 1px solid #0088ff;
  color: #0088ff;
  transition: all 0.2s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.create-spot-map button:hover {
  background-color: #212121;
}

.confirm {
  height: 36px;
  width: 135px;
  border-radius: 1rem;
  left: 50%;
  min-width: 135px;
  bottom: 1rem;
  transform: translateX(-50%);
}

.return {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  left: 1rem;
  height: 45px;
  width: 45px;
  font-size: 1.5rem;
  border-radius: 50%;
}

/* Spotlists Hub  */

.spotlists-hub-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #fff;
  height: 100%;
  min-width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.spotlists-hub-header {
  display: flex;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  border-bottom: 1px solid #404040;
}

.spotlists-hub-sort-options {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem 0rem;
}

.sort-option {
  padding: 0.5rem 1.5rem;
  background-color: #333;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: all 0.2s;
}

.sort-option:hover {
  background-color: #404040;
}

.sort-option.active {
  background-color: #14161a;
  color: #0088ff;
  border: 1px solid #0088ff;
}

.spotlists-hub-body {
  height: 100%;
  width: 100%;
}

.spot-library-spots {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  padding: 1rem;
}

@media (min-width: 1000px) {
  .spot-library-spots {
    grid-template-columns: 1fr 1fr;
  }
}

/* Bookmarks  */

.bookmarks-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.bookmarks-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #404040;
}

.bookmarks-body {
  height: 100%;
}

.empty-bookmarks-message {
  padding: 1rem;
  text-align: center;
}

/* Reports */

.reports-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  min-width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
}

.reports-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.reports-header,
.report-el {
  display: grid;
  grid-template-columns: 30% 15% 45% 10%;
  text-align: center;
  background-color: #fff;
  row-gap: 1px;
  column-gap: 1px;
  border-bottom: 1px solid #fff;
}

.reports-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #212121;
}

.reports-label.delete {
  color: #d5372f;
  cursor: pointer;
}

.reports-empty {
  margin-top: 1rem;
  text-align: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Error Page */

.error-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #212121;
  text-align: center;
  color: #fff;
}

.error-page-body {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1rem;
  align-items: center;
  line-height: 1;
  background-color: #282828;
  padding: 2rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
}

.error-page-type {
  font-size: 1.5rem;
  font-weight: 500;
}

.error-page-status {
  font-size: 4rem;
  font-weight: 500;
}

.error-page-message {
  opacity: 0.8;
}

.home-button {
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  width: fit-content;
  background-color: #0088ff;
  transition: background-color 0.2s;
}

.home-button:hover {
  background-color: #0056b3;
}

/* General Error */

.general-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  color: #d5372f;
  text-align: center;
}

/* Responsiveness */

@media (min-width: 1000px) {
  .loader,
  .profile-container,
  .settings-container,
  .chat-search-container,
  .friends-container,
  .posts-container,
  .spot-detail,
  .post-detail-container,
  .notifications-container,
  .discover-container,
  .area-search-container,
  .create-spot-container,
  .bookmarks-container,
  .error-page-container {
    min-width: 600px;
  }
}

/* Loading Wave  */

.loading-wave-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: #0088ff;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

/* Little Spinner  */

.spinner-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: transparent;
  }
}
